@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

@font-face {
  font-family: "AeonikPro-Regular";
  src: url("/src/fonts/aeonic-pro/AeonikPro-Regular.woff2") format("woff2"),
    url("/src/fonts/aeonic-pro/AeonikPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AeonikPro-Medium";
  src: url("/src/fonts/aeonic-pro/AeonikPro-Medium.woff2") format("woff2"),
    url("/src/fonts/aeonic-pro/AeonikPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.slick-slider {
  overflow: hidden;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.showpageSlider .slick-list {
  margin: 0;
  border-radius: 8px;
}

.showpageSlider .slick-slide > div {
  margin: 0;
}

.MoonLoad {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  font-size: 18px;
  color: #0d263b;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #000;
}

button,
:focus,
input,
textarea,
select {
  outline: none;
}

.openFile {
  display: none;
  transform: translateX(-100%);
  transition: 0.5s;
  z-index: -1;
  height: 0;
  background-color: #fff;
}

.closeFile {
  display: block;
  transform: translateX(0%);
  transition: 0.5s;
  z-index: -10;
  background-color: #fff;
}

.followers {
  /* top: -1px; */
  left: 0;
  z-index: -30;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-out;
}

.nav-link:hover + .followers {
  left: 31px;
  z-index: 10;
}

.btnModel:hover .btnModelTxt {
  color: #fff;
  transition: all ease-in 0.3s;
}

.btnModel:hover .btnModel img {
  fill: #fff;
}

.ant-modal,
.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-content .ant-modal-close {
  right: 10px !important;
}

.modelSlider .slick-dots {
  height: 32px;
  display: flex;
  width: 320px;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  bottom: 20px;
}

.ant-modal-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px !important;
  height: 44px !important;
  background-color: #fcfcfc !important;
  right: 28px !important;
  top: 10px !important;
  opacity: 80% !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 8px !important;
  padding-bottom: 7px !important;
}

.modalSetMobile .ant-modal-body {
  display: flex;
  justify-content: center;
}

/* ----------------Loading Animation ----------------*/

.LoadingAnimation {
  width: 100%;
  height: 100%;
  background-color: white;
  /* opacity: 0.2; */
  /* animation: load 1s ease-out; */
  -webkit-animation: fadeInFromNone 0.8s ease-out;
  -moz-animation: fadeInFromNone 0.8s ease-out;
  -o-animation: fadeInFromNone 0.8s ease-out;
  animation: fadeInFromNone 0.8s ease-out;
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  /* 50% {
    opacity: 0.01;
  } */
  100% {
    opacity: 1;
  }
}

/* ------------------- */
/* simple - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
  z-index: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

/* ----------CateGory----------- */
.categoryLinearText {
  background: linear-gradient(
    to right,
    rgba(167, 207, 223, 0) 50%,
    rgba(255, 255, 255, 0.99) 100%
  );
}
.categoryLinearPrice {
  background: linear-gradient(
    to right,
    rgba(167, 207, 223, 0) 80%,
    rgba(255, 255, 255, 0.99) 100%
  );
}

/* Table */
table,
tr,
td,
th {
  border: 1px solid #f2f2f2;
  padding: 8px 8px;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
}

table tr td {
  color: #a1a1a1;
  font-size: 12px;
}

table tr td:nth-child(1) {
  font-weight: bold;
  font-size: 14px;
  color: #000;
}

table tr th {
  background-color: #fcfcfc;
}

table tr th:nth-child(1) {
  font-weight: bold;
  font-size: 14px;
  color: #000;
  padding: 8px 8px;
}

.widthInherit {
  width: inherit;
}

.items {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  overflow-x: scroll;
  row-gap: 12px;
}

.items::-webkit-scrollbar {
  width: 50px;
  height: 0px;
}

.items::-webkit-scrollbar-thumb {
  background-color: #e8e8f0;
  width: 10px;
  border-radius: 8px;
}

.item {
  width: fit-content;
  border-radius: 8px;
  background-color: #fcfcfc;
  padding: 12px 16px;
  cursor: pointer;
}

.categoryScroll::-webkit-scrollbar {
  width: 2px;
}

.categoryScroll::-webkit-scrollbar-thumb {
  background-color: #1e1e20;
  height: 30px;
  border-radius: 8px;
}

/* --------------------React Slider openedBrands */

/* --------------------React Slider search  */

.horizontal-slider {
  width: 95%;
  margin: auto;
  z-index: 33;
  border-radius: 5px;
}

.example-thumb1 {
  background: rgba(0, 125, 202, 1)
    url("./assets//Category//ReactSliderMenu.svg");
  width: 24px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  border-radius: 16px;
  border: 1px solid #007dca;
  margin-top: -8px;
}

.example-thumb1.active {
  border: 1px solid #007dca;
}

.example-track1 {
  position: relative;
  margin-top: -18px;
  background: #fff;
  border: 1px solid #d2d2d2;
}

.example-track1.example-track1-1 {
  margin-top: -18px;
  background: #007dca;
}

.example-track1.example-track1-2 {
  margin-top: -18px;
  background: #fff;
  border: 1px solid #d2d2d2;
}

.horizontal-slider .example-track1 {
  top: 16px;
  height: 6px;
  border-radius: 5px;
  padding: 2px;
}

/* SHOWPAGE */

.ant-modal-content .ant-modal-close {
  background-color: white !important;
  border: none !important;
}

:where(.css-dev-only-do-not-override-1me4733).ant-modal .ant-modal-close {
  color: #000;
}

/* ------------------------------------------ */
.VerticelScroll::-webkit-scrollbar {
  width: 2px;
  height: 50px;
}

.VerticelScroll::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  width: 2px;
  border-radius: 8px;
}

body::-webkit-scrollbar {
  width: 2px;
  height: 50px;
}

body::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  width: 2px;
  border-radius: 8px;
}

.HorizantalScroll::-webkit-scrollbar {
  width: 50px;
  height: 2px;
}

.HorizantalScroll::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  width: 50px;
  border-radius: 8px;
}

.locationIconChangeColor:hover {
  color: #007dca;
}

.openAccardion {
  min-height: 70px;
  gap: 8px;
  padding-top: 15px;
  padding-bottom: 4px;
}

.CloseAccardion {
  height: 0px;
  overflow: hidden;
}

/* ----------------------------- */
.VerticelScroll::-webkit-scrollbar {
  width: 2px;
  height: 50px;
}

.VerticelScroll::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  width: 2px;
  border-radius: 8px;
}

/* ---------------Market Filter ----------- */
.marketFilter .horizontal-slider {
  width: 95%;
  margin: auto;
  z-index: 33;
  border-radius: 5px;
}

.marketFilter .example-thumb1 {
  background: rgba(0, 125, 202, 1);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  border-radius: 16px;
  border: 4px solid #fff;
  margin-top: -10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.marketFilter .example-thumb1.active {
  border: 4px solid #fff;
}

.marketFilter .example-track1 {
  position: relative;
  margin-top: -18px;
  background: #fff;
  border: 1px solid #d2d2d2;
}

.marketFilter .example-track1.example-track1-1 {
  margin-top: -18px;
  background: #007dca;
}

.marketFilter .example-track1.example-track1-2 {
  margin-top: -18px;
  background: #fff;
  border: 1px solid #d2d2d2;
}

.marketFilter .horizontal-slider .example-track1 {
  top: 16px;
  height: 6px;
  border-radius: 5px;
  padding: 2px;
}

.slider .thumb {
  width: 23px;
  height: 23px;
  cursor: grab;
  background: #007dca;
  border-radius: 50%;
  border: 4px solid #fff;
  top: -10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.24);
}

/* .track-1 {
  background-color: #275efe;
} */

.slider .track-0 {
  border: 1px solid #d2d2d2;
}

.slider .track-1 {
  border: 1px solid #007dca;
}

.slider .track-2 {
  border: 1px solid #d2d2d2;
}

.linearGr {
  background: linear-gradient(
    90deg,
    rgba(167, 207, 223, 0) 50%,
    hsla(0, 0%, 100%, 0.99)
  );
}

/* .languageMobile .css-dev-only-do-not-override-1me4733 {
  top: 280px !important;
} */

.navbaritem {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
}

.activeOne {
  visibility: visible;
  transition: all 0.5s;
  z-index: 333;
}

.hiddenOne {
  visibility: hidden;
  transition: all 0.5s;
  z-index: 333;
  transform: translateY(-100%);
}

/* pulse  search */

.pulse {
  width: 32px;
  height: 32px;
  border: 1px solid #007dca;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: rgba(252, 252, 252, 0.5);
  /* background: linear-gradient(#8a82fb, #407ed7); */
}

.pulse::after,
.pulse::before {
  content: "";
  position: absolute;
  height: 32px;
  width: 32px;
  background-color: rgb(178, 208, 226);
  border-radius: 50%;
  z-index: -1;
  opacity: 0.7;
  /* animation: pulse 4s ease-out infinite; */
}

.pulse::before {
  animation: pulse 1s ease-out infinite;
}

.pulse::after {
  animation: 1s 0.6s ease-out infinite;
}

@keyframes pulse {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.categoryBreadCamp::-webkit-scrollbar {
  width: 20px;
}

.categoryBreadCamp::-webkit-scrollbar-thumb {
  background-color: #1e1e20;
  height: 2px;
  border-radius: 2px;
}

.ant-select-selector {
  padding-left: 28px !important;
}

.iconArrow {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.25);
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  inset-inline-start: auto;
  inset-inline-end: 11px;
  height: 12px;
  font-size: 12px;
  pointer-events: none;

}
